import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Department = () => {
    const [department, setDepartment] = useState([])
    const getDepartment = async () => {
        const { data } = await axios.get('/departments');
        setDepartment(data)
    }
    useEffect(() => {
        getDepartment();
    }, [])
    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>Department Detail</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Department Detail</li>
                    </ul>
                </div>
            </section>
            <section className="services-page">
                <div className="auto-container">
                    <div className="row clearfix">
                        {department.map((item, index) => {
                            return (
                                <div key={index} className="service-block_two col-lg-3 col-md-4 col-sm-6">
                                    <div className="service-block_two-inner">
                                        <span className="service-block_two-icon" ><img src={`../../${item.image}`} alt={item.title} /></span>
                                        <h4 className="service-block_two-heading"><Link to="/">{item.title}</Link></h4>
                                        <a className="service-block_two-more" href="/">Read More</a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Department;