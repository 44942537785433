import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BlogDetail = () => {
    const postId = useParams().id
    const [data, setdata] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/blog/${postId}`);
        setdata(data[0])
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(../../assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>Blog Detail</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="index.html">Home</a></li>
                        <li>Blog Detail</li>
                    </ul>
                </div>
            </section>
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="content-side col-lg-8 col-md-12 col-sm-12">
                            <div className="blog-detail">
                                <div className="blog-detail_inner">
                                    <div className="blog-detail_three-image">
                                        <img src={`../../${data.image}`} alt={data.title} />
                                    </div>
                                    <div className="blog-detail_lower-content">
                                        <div className="d-flex flex-wrap">
                                            <div className="blog-detail_date">{new Date(data.created).toLocaleDateString()}</div>
                                        </div>
                                        <h3 className="blog-detail_heading">{data.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default BlogDetail;