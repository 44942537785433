import axios from "axios";
import { useEffect, useState } from "react";

const OurDoctors = () => {
    const [doctors, setDoctors] = useState([])
    const getDoctors = async () => {
        const { data } = await axios.get('/doctors');
        setDoctors(data)
    }

    useEffect(() => {
        getDoctors();
    }, [])
    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>Expert Doctors</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Doctors</li>
                    </ul>
                </div>
            </section>
            <section className="team-two">
                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="sec-title_title">Meet Our Team</div>
                        <h2 className="sec-title_heading">Certified and experienced <br /> doctors in our clinic</h2>
                    </div>
                    <div className="row clearfix">
                        {doctors.map((item, index) => {
                            return (
                                <div key={index} className="team-block_two col-lg-3 col-md-4 col-sm-6">
                                    <div className="team-block_two-inner">
                                        <div className="team-block_two-image">
                                            <div className="team-block_two-gradient" />
                                            <a href="/"><img src={`../../${item.image}`} alt={item.full_name} /></a>
                                        </div>
                                        <div className="team-block_two-content">
                                            <h6 className="team-block_two-title"><a href="/">{item.full_name}</a></h6>
                                            <div className="team-block_two-category">{item.cert}</div>
                                            <div className="team-block_two-category">
                                                <a href={item.contact} className="fa-brands">{item.contact}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

        </>
    );
}

export default OurDoctors;