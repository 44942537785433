import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const Contact = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/general');
        setData(data[0])
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div>
                <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                    <div className="auto-container">
                        <h2>Contact Us</h2>
                        <ul className="bread-crumb clearfix">
                            <li><a href="/">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </section>

                <section className="contact-one">
                    <div className="auto-container">
                        <div className="sec-title centered">
                            <div className="sec-title_title">CONTACT INFO</div>
                            <h2 className="sec-title_heading">Get in touch!</h2>
                            <div className="sec-title_text">Adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore <br /> magna aliqua. Ut enim ad minim.</div>
                        </div>
                        <div className="row clearfix">
                            <div className="contact-info-block col-lg-3 col-md-6 col-sm-12">
                                <div className="contact-info-block_inner">
                                    <span className="contact-info-block_icon flaticon-placeholder" />
                                    <h4 className="contact-info-block_heading">Clinic Address</h4>
                                    <div className="contact-info-block_text">{data.address}</div>
                                </div>
                            </div>
                            <div className="contact-info-block col-lg-3 col-md-6 col-sm-12">
                                <div className="contact-info-block_inner">
                                    <span className="contact-info-block_icon flaticon-send-mail" />
                                    <h4 className="contact-info-block_heading">Email</h4>
                                    <div className="contact-info-block_text">
                                        <a href={data.email_1}>{data.email_1}</a><br />
                                        <a href={data.email_2}>{data.email_2}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-info-block col-lg-3 col-md-6 col-sm-12">
                                <div className="contact-info-block_inner">
                                    <span className="contact-info-block_icon flaticon-clock" />
                                    <h4 className="contact-info-block_heading">Office Time</h4>
                                    <div className="contact-info-block_text">{data.work_time}</div>
                                </div>
                            </div>
                            <div className="contact-info-block col-lg-3 col-md-6 col-sm-12">
                                <div className="contact-info-block_inner">
                                    <span className="contact-info-block_icon flaticon-telephone" />
                                    <h4 className="contact-info-block_heading">Phone</h4>
                                    <div className="contact-info-block_text">
                                        <a href={data.phone_1}>{data.phone_1}</a><br />
                                        <a href={data.phone_2}>{data.phone_2}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-one">
                    <iframe title="map" src={data.map} allowFullScreen />
                </section>
            </div>

        </>
    );
}

export default Contact;