import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FourDepartment = () => {
    const [department, setDepartment] = useState([])
    const getDepartment = async () => {
        const { data } = await axios.get('/departments');
        setDepartment(data)
    }

    useEffect(() => {
        getDepartment();
        setTimeout(() => {
            if (window.$('.four-item-carousel').length) {
                window.$('.four-item-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    nav: true,
                    rtl: false,
                    smartSpeed: 700,
                    autoplay: 5000,
                    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        800: {
                            items: 3
                        },
                        1024: {
                            items: 4
                        },
                        1200: {
                            items: 4
                        }
                    }
                });
            }
        }, 1000);
    }, [])
    return (
        <section className="department-one" id="department">
            <div className="department-one_image" style={{ backgroundImage: 'url(assets/images/background/1.jpg)' }} />
            <div className="auto-container">
                <div className="sec-title light centered">
                    <div className="sec-title_title">OUR SPECIALTIES</div>
                    <h2 className="sec-title_heading">Medical departments</h2>
                </div>
                <div className="four-item-carousel owl-carousel owl-theme">
                    {department.map((item, index) => {
                        return (
                            <div key={index} className="department-one_block">
                                <div className="department-one_block-inner">
                                    <div className="department-one_block-image">
                                        <Link to="/"><img src={`../../${item.image}`} alt={item.title} /></Link>
                                    </div>
                                    <div className="department-one_block-content">
                                        <span className="department-one_block-icon flaticon-hematology" />
                                        <h5 className="department-one_block-heading"><Link to="/">{item.title}</Link></h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}

export default FourDepartment;