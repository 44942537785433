const Aboutus = () => {
    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>About Us</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </section>
            <section className="about-two style-two">
                <div className="about-two_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-11.png)' }} />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="about-two_content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="about-two_content-inner">
                                <div className="sec-title">
                                    <div className="sec-title_title">About Clinic</div>
                                    <h2 className="sec-title_heading">Short Story About Merit <br /> Clinic Since 1992</h2>
                                </div>
                                <p className="about-two_text">We provide the most full medical services, so every person could have the pportunity o receive qualitative medical help.</p>
                                <p className="about-two_text">Our Clinic has grown to provide a world class facility for the treatment of tooth loss, dental cosmetics and bore advanced restorative dentistry. We are among the most qualified implant providers in the AUS with over 30 years of uality training and experience.</p>
                                <div className="row clearfix">
                                    <div className="about-two_feature col-lg-6 col-md-6 col-sm-6">
                                        <div className="about-two_feature-inner">
                                            <span className="about-two_feature-icon flaticon-thumbs-up" />
                                            <strong>Infection Prevention</strong>
                                        </div>
                                        It is a long established fact <br /> that a reader will be distracted.
                                    </div>
                                    <div className="about-two_feature col-lg-6 col-md-6 col-sm-6">
                                        <div className="about-two_feature-inner">
                                            <span className="about-two_feature-icon flaticon-medical-assistance" />
                                            <strong>Inpatient Services</strong>
                                        </div>
                                        It is a long established fact <br /> that a reader will be distracted.
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-wrap">
                                    <a href="appointment.html" className="theme-btn btn-style-one">
                                        <span className="btn-wrap">
                                            <span className="text-one">More about</span>
                                            <span className="text-two">More about</span>
                                        </span>
                                    </a>
                                    <div className="about-two_signature"><img src="assets/images/icons/signature.png" alt="signature" /></div>
                                    <div className="about-two_author">
                                        Michel Fernandez<br />
                                        <span>Founder of Merit Hospital</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-two_images-column col-lg-6 col-md-12 col-sm-12">
                            <div className="about-two_images-inner">
                                <div className="about-two_video">
                                    <img src="assets/images/resource/video-image.jpg" alt="video-icon" />
                                    <a href="https://www.youtube.com/watch?v=kxPCFljwJws" className="lightbox-video about-two_video-box fa fa-play"><i className="ripple" /></a>
                                </div>
                                <div className="about-two_tag">
                                    <img src="assets/images/icons/tag-icon.png" alt="tag-icon" />
                                </div>
                                <div className="about-two_image">
                                    <img src="assets/images/resource/about-3.jpg" alt="about-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cta-one">
                <div className="cta-one_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-12.png)' }} />
                <div className="auto-container">
                    <div className="cta-one_inner-container">
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                            <span className="cta-one_icon flaticon-hospital" />
                            <div className="cta-one_content">
                                <div className="cta-one_title">Quality &amp; Excelence</div>
                                <h2 className="cta-one_heading">Expect Nothing But The Best <br /> From Medical Technology</h2>
                            </div>
                            <div className="cta-one_button">
                                <a href="appointment.html" className="theme-btn btn-style-three">
                                    <span className="btn-wrap">
                                        <span className="text-one">Read More</span>
                                        <span className="text-two">Read More</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="modern-one">
                <div className="modern-one_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-2.png)' }} />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="modern-one_content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="modern-one_content-inner">
                                <div className="sec-title">
                                    <div className="sec-title_title">WHAT WE DO</div>
                                    <h2 className="sec-title_heading">We Deliver Quality Care <br /> Giving Services</h2>
                                    <div className="sec-title_text">Ut enim ad minim. facilisis suspendisseipsum dolor sit amet dolore consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et  magna aliqua enim ad minim veniam, quis nostrud.</div>
                                </div>
                                <div className="modern-one_block">
                                    <div className="modern-one_block-inner">
                                        <span className="modern-one_icon flaticon-stethoscope" />
                                        <strong className="modern-one_bold">Infection Prevention</strong>
                                        There are many variations of passages of <br /> Lorem Ipsum available majority.
                                    </div>
                                </div>
                                <div className="modern-one_button">
                                    <a href="about.html" className="theme-btn btn-style-two">
                                        <span className="btn-wrap">
                                            <span className="text-one">Learn More</span>
                                            <span className="text-two">Learn More</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="modern-one_image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="modern-one_image-inner">
                                <div className="modern-one_image">
                                    <img src="assets/images/resource/modern.jpg" alt="modern" />
                                </div>
                                <div className="counter-one" style={{ backgroundImage: 'url(assets/images/background/pattern-5.png)' }}>
                                    <div className="row clearfix">
                                        <div className="counter-one_block col-lg-4 col-md-6 col-sm-6">
                                            <div className="counter-one_inner">
                                                <span className="counter-one_icon flaticon-doctor" />
                                                <div className="counter-one_counter d-flex">+<span className="odometer" data-count={350} /></div>
                                                <div className="counter-one_text">Doctor’s Team</div>
                                            </div>
                                        </div>
                                        <div className="counter-one_block col-lg-4 col-md-6 col-sm-6">
                                            <div className="counter-one_inner">
                                                <span className="counter-one_icon flaticon-surgery-room" />
                                                <div className="counter-one_counter d-flex"><span className="odometer" data-count={2150} /></div>
                                                <div className="counter-one_text">Success Mission</div>
                                            </div>
                                        </div>
                                        <div className="counter-one_block col-lg-4 col-md-6 col-sm-6">
                                            <div className="counter-one_inner">
                                                <span className="counter-one_icon flaticon-medical-team" />
                                                <div className="counter-one_counter d-flex">+<span className="odometer" data-count={225} /></div>
                                                <div className="counter-one_text">Successfull Surgeries</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Aboutus;