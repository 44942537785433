import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const Footer = () => {
    const [data, setData] = useState([]);
    const [rand, setRand] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/general');
        setData(data[0])
        const { data: rand } = await axios.get('/news-footer');
        setRand(rand)
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (window.$('.scroll-to-target').length) {
            window.$(".scroll-to-target").on('click', function () {
                var target = window.$(this).attr('data-target');
                // animate
                window.$('html, body').animate({
                    scrollTop: window.$(target).offset().top
                }, 1500);

            });
        }

        window.$(window).on('scroll', function () {
            if (window.$('.main-header').length) {
                var windowpos = window.$(window).scrollTop();
                var siteHeader = window.$('.main-header');
                var scrollLink = window.$('.scroll-to-top');

                var HeaderHight = window.$('.main-header').height();
                if (windowpos >= HeaderHight) {
                    siteHeader.addClass('fixed-header');
                    scrollLink.fadeIn(300);
                } else {
                    siteHeader.removeClass('fixed-header');
                    scrollLink.fadeOut(300);
                }

            }
        });

    }, [])
    return (
        <footer className="main-footer" style={{ backgroundImage: 'url(assets/images/background/pattern-8.png)' }}>
            <div className="auto-container">
                <div className="main-footer_widgets">
                    <div className="row clearfix">
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="main-footer_column col-lg-7 col-md-6 col-sm-12">
                                    <div className="footer-widget logo-widget">
                                        <div className="main-footer_logo">
                                            <Link to="/"><img src={data.logo} alt="footer-logo" /></Link>
                                        </div>
                                        <div className="main-footer_text">
                                            {data.description}
                                        </div>
                                    </div>
                                </div>
                                <div className="main-footer_column col-lg-5 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h4 className="main-footer_title">Service</h4>
                                        <ul className="main-footer_list">
                                            <li><Link to="/specialists">Specialist</Link></li>
                                            <li><Link to="/blog">Blog</Link></li>
                                            <li><Link to="/our-doctors">Our Doctors</Link></li>
                                            <li><Link to="/gallery">Gallery</Link></li>
                                            {/* <li><Link to="/about-us">About Us</Link></li> */}
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget news-widget">
                                        <h4 className="main-footer_title">Recent Posts</h4>
                                        {rand.map((i, x) => {
                                            return (
                                                <div key={x} className="news-widget-block">
                                                    <div className="news-widget_image">
                                                        <img src={`../../${i.image}`} alt="new-1" />
                                                    </div>
                                                    <div className="news-widget_post-date">{new Date(i.created).toLocaleDateString()}</div>
                                                    <h6 className="news-widget_title"><Link to={`/blog-detail/${i.id}`}>{i.title}</Link></h6>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget contact-widget">
                                        <h4 className="main-footer_title">Contact Info</h4>
                                        <ul className="main-footer_contact-list">
                                            <li><span className="icon fa-solid fa-phone fa-fw" />{data.address}</li>
                                            <li><span className="icon fa-solid fa-envelope fa-fw" /> <a href={data.email_1}>{data.email_1}</a></li>
                                            <li><span className="icon fa-solid fa-clock fa-fw" />{data.work_time}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-footer_bottom">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="footer_copyright">Copyright 2023 Sardam Hospital All Rights Reserved</div>
                        <div className="footer-scroll_top scroll-to-target" data-target="html"><span className="fa fa-arrow-up" /></div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;