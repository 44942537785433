import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './Pages/Home';
import Department from './Pages/Department';
import Specialist from './Pages/Specialist';
import News from './Pages/News';
import OurDoctors from './Pages/OurDoctors';
import Gallery from './Pages/Gallery';
import Aboutus from './Pages/Aboutus';
import Contact from './Pages/Contact';
import BlogDetail from './Pages/BlogDetail';

const withLoading = (WrappedComponent) => {
  return function LoadingComponent(props) {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
      setLoading(true);
      const timer = setTimeout(() => setLoading(false), 500);
      return () => clearTimeout(timer);
    }, [location]);

    return (
      <>
        {loading && <div className="preloader"></div>}
        <WrappedComponent {...props} />
      </>
    );
  };
};

function App() {
  const spaceFooter = (
    <section className="newsletter-one">
      <div className="auto-container">
        <div className="newsletter-one_inner-container" style={{ backgroundImage: 'url(assets/images/background/pattern-7.png)' }}>
          <h2 className="text-center">
            Sardam Hospital
          </h2>
        </div>
      </div>
    </section>
  )

  const HomeWithLoading = withLoading(() => <><Home />{spaceFooter}</>);
  const DepartmentWithLoading = withLoading(() => <><Department />{spaceFooter}</>);
  const SpecialistWithLoading = withLoading(() => <><Specialist />{spaceFooter}</>);
  const NewsWithLoading = withLoading(() => <><News />{spaceFooter}</>);
  const BlogDetailLoading = withLoading(() => <><BlogDetail />{spaceFooter}</>);
  const OurTeamWithLoading = withLoading(() => <><OurDoctors />{spaceFooter}</>);
  const GalleryWithLoading = withLoading(() => <><Gallery />{spaceFooter}</>);
  const AboutusWithLoading = withLoading(() => <><Aboutus /></>);
  const ContactWithLoading = withLoading(() => <><Contact /></>);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomeWithLoading />} />
        <Route path="/department" element={<DepartmentWithLoading />} />
        <Route path="/specialists" element={<SpecialistWithLoading />} />
        <Route path="/blog" element={<NewsWithLoading />} />
        <Route path="/blog-detail/:id" element={<BlogDetailLoading />} />
        <Route path="/our-doctors" element={<OurTeamWithLoading />} />
        <Route path="/gallery" element={<GalleryWithLoading />} />
        <Route path="/about-us" element={<AboutusWithLoading />} />
        <Route path="/contact" element={<ContactWithLoading />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;