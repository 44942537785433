import axios from "axios";
import { useEffect, useState } from "react";

const Specialist = () => {
    const [specialist, setspecialist] = useState([]);
    const [doctorby, setDoctorby] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/dcotor-by/${1}`);
        setDoctorby(data)
        const { data: specialist } = await axios.get('/specialist');
        setspecialist(specialist)
    }

    useEffect(() => {
        getData();
    }, [])

    const [activeTab, setActiveTab] = useState('');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>Specialist</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="index.html">Home</a></li>
                        <li>Specialist & Doctors</li>
                    </ul>
                </div>
            </section>

            <section className="services-single-section">
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="widgets-column col-lg-3 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="services-widget category-widget">
                                    <ul className="cat-list">
                                        {specialist.map((i, x) => {
                                            const tabId = `#tab-${x}`;
                                            return (
                                                <li key={x} className={activeTab === tabId ? 'active' : ''} onClick={() => {
                                                    handleTabClick(tabId)
                                                }}>

                                                    <a href="/" onClick={(e) => {
                                                        e.preventDefault();
                                                        axios.get(`/dcotor-by/${i.id}`).then((res) => {
                                                            setDoctorby(res.data)
                                                        })
                                                    }}  >{i.title}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <div className="services-widget schedule-widget">
                                    <div className="image">
                                        <img src="assets/images/resource/service-2.jpg" alt />
                                        <div className="overlay-box">
                                            <div className="content">
                                                <span className="icon flaticon-clock" />
                                                <h3>Doctor <br /> Schedule</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-column col-lg-9 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="row clearfix">
                                    {doctorby.map((i, x) => {
                                        return (
                                            <div key={x} className="team-block-two col-lg-4 col-md-6 col-sm-12">
                                                <div className="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                    <div className="image">
                                                        <img src={i.image} alt={i.full_name} />
                                                    </div>
                                                    <div className="lower-content">
                                                        <div className="content">
                                                            <h5><a href="#">{i.full_name}</a></h5>
                                                            <div className="designation">{i.cert}</div>
                                                            <ul className="social-box">
                                                                <li className="facebook">{i.contact}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Specialist;