import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const News = () => {
    const [data, setData] = useState([]);
    const [rand, setRand] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/news');
        setData(data)
        const { data: rand } = await axios.get('/latest-news');
        setRand(rand)
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>News & Activities</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Latest News</li>
                    </ul>
                </div>
            </section>
            <div className="sidebar-page-container left-sidebar">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                            <aside className="sidebar sticky-top">
                                <div className="sidebar-widget style-two post-widget">
                                    <div className="widget-content">
                                        <h4>Recent Posts</h4>
                                        {rand.map((i, x) => {
                                            const dateObj = new Date(i.created);
                                            const day = dateObj.getDate();
                                            const month = dateObj.toLocaleString('default', { month: 'short' });
                                            const year = dateObj.getFullYear();
                                            return (
                                                <div key={x} className="post">
                                                    <div className="thumb"><Link to={`/blog-detail/${i.id}`}><img src={i.image} alt={i.title} /></Link></div>
                                                    <div className="post-date">{day} {month}, {year}</div>
                                                    <Link to={`/blog-detail/${i.id}`}>{i.title}</Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="sidebar-widget expert_widget" style={{ backgroundImage: 'url(assets/images/background/pattern-33.png)' }}>
                                    <span className="color-layer" />
                                    <h3>Ready To Get Expert <br /> Our Service</h3>
                                    <a href="/" className="theme-btn btn-style-one">
                                        <span className="btn-wrap">
                                            <span className="text-one">Contact Now</span>
                                            <span className="text-two">Contact Now</span>
                                        </span>
                                    </a>
                                    <div className="image">
                                        <img src="assets/images/resource/doctor-1.png" alt="news" />
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <div className="content-side right-sidebar col-lg-8 col-md-12 col-sm-12">
                            <div className="blog-classic">
                                {data.map((i, x) => {
                                    const dateObj = new Date(i.created);
                                    const day = dateObj.getDate();
                                    const month = dateObj.toLocaleString('default', { month: 'short' });
                                    const year = dateObj.getFullYear();
                                    const truncatedContent = i.content.length > 200 ? i.content.slice(0, 200) + '...' : i.content;
                                    return (
                                        <div key={x} className="news-block_three style-two">
                                            <div className="news-block_three-inner">
                                                <div className="news-block_three-image">
                                                    <img src={i.image} alt={i.title} />
                                                </div>
                                                <div className="news-block_three-content">
                                                    <div className="d-flex flex-wrap">
                                                        <ul className="news-block_three-meta">
                                                            <li><span className="icon fa-solid fa-calendar-days fa-fw" />{day} {month}, {year}</li>
                                                        </ul>
                                                    </div>
                                                    <h3 className="news-block_three-heading"><Link to={`/blog-detail/${i.id}`}></Link></h3>
                                                    <div className="news-block_three-text" contentEditable='true' dangerouslySetInnerHTML={{ __html: truncatedContent }}></div>
                                                    <Link className="news-block_three-more-two" to={`/blog-detail/${i.id}`}>read more <span className="fa-solid fa-angles-right fa-fw" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default News;