import { useEffect, useState } from "react";
import FourDepartment from "../Components/Departments";
import axios from "axios";
import { Link } from "react-router-dom";

const Home = () => {
    useEffect(() => {
        if (window.$(".odometer").length) {
            window.$('.odometer').appear();
            window.$('.odometer').appear(function () {
                var odo = window.$(".odometer");
                odo.each(function () {
                    var countNumber = window.$(this).attr("data-count");
                    window.$(this).html(countNumber);
                });
                window.odometerOptions = {
                    format: 'd',
                };
            });
        }
    }, [])

    const [intro, setIntro] = useState([]);
    const [slider, setslider] = useState([]);
    const [rand, setRand] = useState([]);
    const [specialtis, setspecialtis] = useState([]);
    const [latestNews, setLatestNews] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/intro');
        setIntro(data[0])
        const { data: slider } = await axios.get('/slider');
        setslider(slider)
        const { data: rand } = await axios.get('/doctors-rand');
        setRand(rand)
        const { data: specialist } = await axios.get('/specialist');
        setspecialtis(specialist)
        const { data: news } = await axios.get('/latest-news');
        setLatestNews(news)
    }

    useState(() => {
        getData();
    }, [])

    useEffect(() => {
        if (slider.length > 1) {
            if (window.$('.main-slider-carousel').length) {
                window.$('.main-slider-carousel').owlCarousel({
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    loop: true,
                    margin: 0,
                    nav: true,
                    smartSpeed: 500,
                    autoplay: 6000,
                    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        800: {
                            items: 1
                        },
                        1024: {
                            items: 1
                        },
                        1200: {
                            items: 1
                        }
                    }
                });
            }
        }
    }, [slider])

    if (window.$('.lightbox-video').length) {
        window.$('.lightbox-video').magnificPopup({
            // disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            iframe: {
                patterns: {
                    youtube: {
                        index: 'youtube.com',
                        id: 'v=',
                        src: 'https://www.youtube.com/embed/%id%'
                    },
                },
                srcAction: 'iframe_src',
            },
            fixedContentPos: false
        });
    }

    const [activeTab, setActiveTab] = useState('');

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div className="page-wrapper">
            <section className="slider-one">
                <div className="main-slider-carousel owl-carousel owl-theme">
                    {slider.map((i, x) => {
                        return (
                            <div key={x} className="slider-one_slide" style={{ backgroundImage: `url(${i.image})` }}>
                                <div className="auto-container">
                                    <div className="slider-one_content">
                                        <div className="slider-one_content-inner">
                                            <div dangerouslySetInnerHTML={{ __html: i.content }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </section>

            <section className="about-one" id="about">
                <div className="about-one_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-1.png)' }} />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="about-one_image-inner-column">
                                <div className="about-one_icon">
                                    <img src="assets/images/icons/plus.png" alt="plus" />
                                </div>
                                <div className="about-one_image">
                                    <img src={intro.image_1} alt={intro.image_1} />
                                </div>
                                <div className="about-one_image-two">
                                    <img src={intro.image_2} alt={intro.image_1} />
                                </div>
                            </div>
                        </div>

                        <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="about-one_content-inner-column">
                                <div className="sec-title">
                                    <div className="sec-title_title">Visit Our Hospital</div>
                                    <h2 className="sec-title_heading">{intro.title}</h2>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: intro.content }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FourDepartment />
            <section className="testimonial-one">
                <div className="testimonial-one_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-6.png)' }} />
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="team-one_content-column col-lg-5 col-md-12 col-sm-12">
                            <div className="team-one_content-inner">
                                <div className="sec-title">
                                    <div className="sec-title_title">meet our team</div>
                                    <h2 className="sec-title_heading">Certified and experienced doctors in our clinic</h2>
                                </div>
                                <p className="team-one_text">
                                    Meet our exceptional team: certified, experienced doctors dedicated to your health.
                                    Offering advanced, personalized care, they ensure the best medical outcomes with compassion and expertise.
                                </p>
                                <p className="team-one_text">
                                    Our team's dedication to staying abreast of the latest advancements in medicine guarantees
                                    you have access to cutting-edge therapies and a comprehensive range of services
                                    to meet all your health needs.
                                </p>
                                <div className="team-one_buttons">
                                    <Link to="/doctors" className="theme-btn btn-style-two">
                                        <span className="btn-wrap">
                                            <span className="text-one">view all doctors</span>
                                            <span className="text-two">view all doctors</span>
                                        </span>
                                    </Link>
                                </div>
                                <div className="team-one_telescope">
                                    <img src="assets/images/resource/telescope.png" alt="telescope" />
                                </div>
                            </div>
                        </div>
                        <div className="team-one_teams-column col-lg-7 col-md-12 col-sm-12">
                            <div className="team-one_teams-inner">
                                <div className="row clearfix">
                                    {rand.map((i, x) => {
                                        return (
                                            <div key={x} className="team-block_one col-lg-6 col-md-6 col-sm-12">
                                                <div className="team-block_one-inner">
                                                    <div className="team-block_one-image">
                                                        <div className="team-block_one-gradient" />
                                                        <a href="/"><img src={i.image} alt={i.full_name} /></a>
                                                        <div className="team-block_one-overlay">
                                                            <h6 className="team-block_one-title"><a href="/">{i.full_name}</a></h6>
                                                            <div className="team-block_one-category">{i.cert} </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="video-section" style={{ backgroundImage: 'url(assets/images/background/8.jpg)' }}>
                <div className="auto-container">
                    <a href="https://www.youtube.com/watch?v=kxPCFljwJws" className="lightbox-video video-box">
                        <span className="fa fa-play"><i className="ripple" /></span>
                    </a>
                    <h4>Watch Our Video</h4>
                </div>
            </section>
            <section className="specialist-one">
                <div className="about-two_pattern" style={{ backgroundImage: 'url(assets/images/background/pattern-11.png)' }} />
                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="sec-title_title">OUR Specialist</div>
                        <h2 className="sec-title_heading">Medical Specialist</h2>
                    </div>
                    <div className="specialist-info-tabs">
                        <div className="specialist-tabs tabs-box">
                            <ul className="tab-btns tab-buttons clearfix">
                                {specialtis.map((i, x) => {
                                    const tabId = `#tab-${x}`;
                                    return (
                                        <li key={x} data-tab={tabId} className={`tab-btn ${activeTab === tabId ? 'active-btn' : ''}`} onClick={() => { handleTabClick(tabId) }}>
                                            <img src={i.image} alt={i.title} />
                                            {i.title}
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div>
                </div>
            </section>
            <section className="team-one">
                <div className="team-one_pattern" data-parallax="{&quot;x&quot; : 50}" style={{ backgroundImage: 'url(assets/images/icons/team-icon.png)' }} />
                <div className="auto-container">
                    <div className="sec-title centered">
                        <div className="sec-title_title">OUR BLOG</div>
                        <h2 className="sec-title_heading">You get every single <br /> news feeds.</h2>
                    </div>
                    <div className="row clearfix">
                        {latestNews.map((i, x) => {
                            const dateObj = new Date(i.created);
                            const day = dateObj.getDate();
                            const month = dateObj.toLocaleString('default', { month: 'short' });
                            const year = dateObj.getFullYear();
                            const truncatedContent = i.content.length > 150 ? i.content.slice(0, 150) + '...' : i.content;
                            return (
                                <div className="news-block_one col-lg-4 col-md-6 col-sm-12">
                                    <div className="news-block_one-inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <div className="news-block_one-image">
                                            <Link to={`/blog-detail/${i.id}`}><img src={i.image} alt={i.title} /></Link>
                                        </div>
                                        <div className="news-block_one-content">
                                            <div className="news-block_one-date"><span>{day}</span> {month} {year}</div>
                                            <h5 className="news-block_one-heading">
                                                <Link to={`/blog-detail/${i.id}`}>{i.title}</Link>
                                            </h5>
                                            <div className="news-block_one-text" contentEditable='true' dangerouslySetInnerHTML={{ __html: truncatedContent }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Home;