import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";

const Header = () => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/general');
        setData(data[0])
    }

    useEffect(() => {
        getData();
    }, [])

    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('mobile-menu-visible', isMobileMenuVisible);
    }, [isMobileMenuVisible]);

    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const renderLink = (path, name) => (
        <Link to={path} onClick={windowWidth < 1020 ? toggleMobileMenu : undefined}>
            {name}
        </Link>
    );

    const renderMenu = () => (
        <ul className="navigation clearfix">
            <li className={isActive('/') ? 'current' : ''}>{renderLink('/', 'Home')}</li>
            <li className={isActive('/department') ? 'current' : ''}>{renderLink('/department', 'Department')}</li>
            <li className={isActive('/specialists') ? 'current' : ''}>{renderLink('/specialists', 'Specialists')}</li>
            <li className={isActive('/blog') ? 'current' : ''}>{renderLink('/blog', 'Blog')}</li>
            <li className={isActive('/our-doctors') ? 'current' : ''}>{renderLink('/our-doctors', 'Our Doctors')}</li>
            <li className={isActive('/gallery') ? 'current' : ''}>{renderLink('/gallery', 'Gallery')}</li>
            {/* <li className={isActive('/about-us') ? 'current' : ''}>{renderLink('/about-us', 'About us')}</li> */}
            <li className={isActive('/contact') ? 'current' : ''}>{renderLink('/contact', 'Contact')}</li>
        </ul>
    );


    return (
        <header className="main-header">
            <div className="header-upper">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="logo-box">
                            <div className="logo">
                                <Link to="/"><img src={`../../${data.logo}`} alt="logo" /></Link>
                            </div>
                        </div>
                        <div className="upper-right d-flex flex-wrap">
                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-clock" /></div>
                                <ul>
                                    <li>{data.work_time}</li>
                                </ul>
                            </div>
                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-send-mail" /></div>
                                <ul>
                                    <li><strong>{data.phone_1}</strong></li>
                                    <li><a href={data.email_1}>{data.email_1}</a></li>
                                </ul>
                            </div>
                            <div className="upper-column info-box">
                                <div className="icon-box"><span className="flaticon-placeholder" /></div>
                                <ul>
                                    <li>{data.address}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-lower">
                <div className="auto-container">
                    <div className="inner-container d-flex justify-content-between align-items-center flex-wrap">
                        <div className="nav-outer">
                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-header">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                        <span className="icon-bar" />
                                    </button>
                                </div>
                                <div className="navbar-collapse scroll-nav collapse clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix">
                                        <li className={isActive('/') ? 'current' : ''}><Link to="/" >Home</Link></li>
                                        <li className={isActive('/specialists') ? 'current' : ''}><Link to="/specialists">Specialists</Link></li>
                                        <li className={isActive('/blog') ? 'current' : ''}><Link to="/blog">Blog</Link></li>
                                        <li className={isActive('/our-doctors') ? 'current' : ''}><Link to="/our-doctors">Our Doctors</Link></li>
                                        <li className={isActive('/gallery') ? 'current' : ''}><Link to="/gallery">Gallery</Link></li>
                                        {/* <li className={isActive('/about-us') ? 'current' : ''}><Link to="/about-us">About us</Link></li> */}
                                        <li className={isActive('/contact') ? 'current' : ''}><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="outer-box d-flex align-items-center flex-wrap">
                            <ul className="header-social_box">
                                <li><a href="https://www.twitter.com/" className="fa-brands"> <i className="fa-brands fa-twitter fa-fw" /> </a></li>
                                <li><a href="https://www.facebook.com/" className="fa-brands"> <i className="fa-brands fa-facebook-f fa-fw" /> </a></li>
                                <li><a href="https://dribbble.com/" className="fa-brands"> <i className="fa-brands fa-dribbble fa-fw" /> </a></li>
                                <li><a href="https://www.linkedin.com/" className="fa-brands"> <i className="fa-brands fa-linkedin fa-fw" /> </a></li>
                            </ul>
                        </div>
                        <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                            <span className="icon flaticon-menu" />
                        </div>
                    </div>
                </div>
            </div>
            {isMobileMenuVisible && (
                <div className="mobile-menu">
                    <div className="menu-backdrop" onClick={toggleMobileMenu}></div>
                    <div className="close-btn" onClick={toggleMobileMenu}>
                        <span className="icon flaticon-close"></span>
                    </div>
                    <div className="menu-box">
                        <div className="nav-logo">
                            <Link to="/">
                                <img src={`../../${data.logo}`} alt="logo-mobile" />
                            </Link>
                        </div>
                        <div className="menu-outer" style={{ textAlign: "right" }}>
                            {renderMenu()}
                        </div>
                    </div>
                </div>
            )}
            <div className="sticky-header">
                <div className="auto-container">
                    <div className="inner-container d-flex justify-content-between align-items-center flex-wrap">
                        <div className="logo">
                            <Link to="/"><img src={`../../${data.logo}`} alt="logo-index" /></Link>
                        </div>
                        <div className="right-box">
                            <nav className="main-menu">
                                {renderMenu()}
                            </nav>
                            <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                                <span className="icon flaticon-menu" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;