import axios from "axios";
import { useEffect, useState } from "react";

const Gallery = () => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/get-gallery');
        setData(data)
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (data) {
            if (window.$('.lightbox-image').length) {
                window.$('.lightbox-image').magnificPopup({
                    type: 'image',
                    gallery: {
                        enabled: true
                    }
                });
            }
        }
    }, [data])
    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(assets/images/background/3.jpg)' }}>
                <div className="auto-container">
                    <h2>Gallery</h2>
                    <ul className="bread-crumb clearfix">
                        <li><a href="/">Home</a></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </section>
            <section className="gallery-one">
                <div className="auto-container">
                    <div className="mixitup-gallery">
                        <div className="row clearfix">
                            {data.map((i, x) => {
                                return (
                                    <div className="project-block col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={i.image} alt={i.caption} />
                                                <div className="overlay-box">
                                                    <div className="overlay-inner">
                                                        <div className="overlay-content">
                                                            <div className="icon-box">
                                                                <span className="icon icon-heart1" />
                                                            </div>
                                                            <h6 className="text-primary">{i.caption}</h6>
                                                            <a className="plus lightbox-image" href={i.image} data-fancybox={`image-${x}`} data-caption><span className="flaticon-plus" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Gallery;